<template>
  <div class="setting page-list">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title class="title">
              <h1>Configuraciones</h1>
              <v-spacer></v-spacer>
              <v-btn
                color="second"
                class="white--text"
                @click="btnSave()"
                :loading="loader"
              >
                <v-icon>mdi-content-save</v-icon>
                Guardar
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <div class="title-setting">
                    Agregar el correo en donde recibirá notificaciones de los
                    pedidos
                  </div>
                  <v-text-field
                    label="Correo electrónico"
                    outlined
                    dense
                    prepend-inner-icon="mdi-email"
                    :autocomplete="'off'"
                    :rules="emailRules"
                    v-model="item.email"
                    color="second"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row>
                <v-col cols="12">
                  <div class="title-setting">
                    <div>Banco Central de Venezuela</div>
                    <span>$1 = {{ item.moneda }}</span>
                  </div>
                  <v-text-field
                    label="Agregar la tasa del día: *"
                    prepend-inner-icon="mdi-currency-usd"
                    outlined
                    dense
                    :autocomplete="'off'"
                    :rules="monedaRules"
                    v-model="item.moneda"
                    color="second"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import useValidate from "../../../../shared/validate/useValidate";
import SettingStorage from "../../../../core/storage/setting-storage";
import SettingServices from "../../../../core/services/setting-services";

export default {
  name: "Setting",
  data: () => ({
    valid: false,
    loader: false,
    item: { email: "", moneda: "" },
    emailRules: [useValidate["required"], useValidate["email"]],
    monedaRules: [useValidate["required"], useValidate["number"]],
  }),
  created() {
    this.settingAll();
    this.getSettingAll();
  },
  methods: {
    async btnSave() {
      const { settingLast } = SettingStorage();
      const item = await settingLast();
      const validate = this.$refs.form.validate();
      if (validate) {
        if (item !== undefined) {
          this.updateSetting();
        } else {
          this.saveSetting();
        }
      }
    },
    async settingAll() {
      const { settingLast } = SettingStorage();
      const item = await settingLast();
      if (item) {
        this.item = item;
      }
    },
    getSettingAll() {
      const { getSettingAll } = SettingServices();
      const { settingAdd } = SettingStorage();
      getSettingAll().then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          response.data.map((item) => {
            settingAdd(item);
          });
          this.settingAll();
        }
      });
    },
    updateSetting() {
      this.loader = true;
      const { updateSetting } = SettingServices();
      const { settingAdd } = SettingStorage();
      updateSetting(this.item.id, this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          settingAdd(response.data);
          this.settingAll();
          this.$toast.success(
            "La configuración se ha actualizado de forma exitosa"
          );
        }
        this.loader = false;
      });
    },
    saveSetting() {
      this.loader = true;
      const { saveSetting } = SettingServices();
      const { settingAdd } = SettingStorage();
      saveSetting(this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          settingAdd(response.data);
          this.settingAll();
          this.$toast.success(
            "La configuración se ha registrado de forma exitosa"
          );
        }
        this.loader = false;
      });
    },
  },
};
</script>

<style lang="scss">
.setting {
  .title-setting {
    font-size: 16px;
    font-weight: 400;
    margin: 20px 0px 15px;
    display: flex;
    justify-content: space-between;
    line-height: 1.8rem;

    span {
      font-weight: 600;
    }
  }

  .v-divider {
    margin-top: 10px;
  }

  i {
    margin-right: 5px;
  }
}
</style>
